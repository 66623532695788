import React, { useRef, MouseEventHandler } from "react";

import Layout from "../components/layout";
import { useStaticQuery, graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import ScrollButton from '../components/scroll-button';

const IndexPage = () => {
  const {
    strapiHomepage: homePage,
    allStrapiTechnologies: technologies,
  } = useStaticQuery(graphql`
    {
      strapiHomepage {
        name
        title
        about_me
        email
      }
      allStrapiTechnologies {
        nodes {
          id
          name
        }
      }
    }
  `);

  const scrollToNext: MouseEventHandler = $event => {
    $event.preventDefault();
    $event.stopPropagation();
    const target = $event.currentTarget as HTMLElement;
    const scrollPoint =
      target.parentElement.offsetHeight + target.parentElement.offsetTop;
    window.scrollTo(0, scrollPoint);
    return false;
  };
  return (
    <Layout>
      <section className="landing">
        <div className="split">
          <div className="side">
            <h2>{homePage.title}</h2>
          </div>
          <div className="divider"></div>
          <div className="side">
            <h1>{homePage.name}</h1>
          </div>
        </div>
        <ScrollButton onClick={scrollToNext} />
        {/* <a href="#" onClick={scrollToNext} className="scroller"></a> */}
      </section>
      <section className="about">
        <div className="centered">
          <h2>About Me</h2>
          <ReactMarkdown source={homePage.about_me} />
        </div>
        <ScrollButton onClick={scrollToNext} />
      </section>
      <section className="tech">
        <div className="split">
          <div className="side">
            <h2>Technologies</h2>
          </div>
          <div className="divider"></div>
          <div className="side">
            <ul>
              {technologies.nodes.map(tech => (
                <li key={tech.id}>{tech.name}</li>
              ))}
            </ul>
          </div>
        </div>
        <ScrollButton onClick={scrollToNext} />
      </section>
      <section className="contact">
        <p>
          You can reach me at{" "}
          <a href={`mailto:${homePage.email}`}>{homePage.email}</a>
        </p>
      </section>
    </Layout>
  );
};

export default IndexPage;
